import React, { useReducer } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import FinalStep from './steps/final-step';
import { Line } from 'rc-progress';
import SingleMultiSelect from './steps/single-multi';
import Step1 from './steps/step1';
import constants from '../../constants/configurator';
import logoImg from '../../images/nav/logo.svg';
import moneyGraphics from '../../images/configurator/money-graphics.svg';
import styled from 'styled-components';
import util from './util';

const Configurator = ({ setBgImg }) => {
	const data = useStaticQuery(query);

	const ALL_PACKAGES = data.allPackages.edges.map(({ node }) => node);
	const [state, dispatch] = useReducer(util.reducer, util.initialState);

	const {
		currentStep,
		step1Results,
		step2Results,
		step3Results,
		step4Results,
		step5Results,
		progressPercentage,
		numberOfSteps,
		goToConfigPackages,
		configPackages,
		enableNextStep,
		tvCount
	} = state;

	const onButtonNextClick = () => {
		switch (currentStep) {
			case 1: {
				if (step1Results === null) {
					alert('Изберете поне едно от полетата');
					return;
				}
				break;
			}
			case 2: {
				if (step2Results === null) {
					alert('Изберете поне едно от полетата');
					return;
				}
				break;
			}
			case 3: {
				if (step3Results === null) {
					alert('Изберете поне едно от полетата');
					return;
				}
				if (step1Results === 'Само интернет') {
					const parsedInternetPackages = util.parseInternetPackage(
						step2Results,
						step3Results,
						ALL_PACKAGES
					);
					dispatch({
						type: 'final_step',
						configPackages: parsedInternetPackages
					});
					setBgImg(true);
					return;
				}
				break;
			}
			case 4: {
				if (step4Results === null) {
					alert('Изберете поне едно от полетата');
					return;
				}
				break;
			}
			case 5: {
				if (step5Results === null) {
					alert('Изберете поне едно от полетата');
					return;
				}

				const parsedPackages = util.parseTvPackage(
					step2Results,
					step3Results,
					tvCount !== ' ' && tvCount !== ''
						? [
								{
									option: tvCount + ' тв',
									isSelected: true
								}
						  ]
						: step4Results,
					step5Results,
					ALL_PACKAGES
				);
				dispatch({
					type: 'final_step',
					configPackages: parsedPackages
				});
				setBgImg(true);
				break;
			}
			default: {
				alert('Изберете поне едно от полетата');
			}
		}
		dispatch({ type: 'next_step' });
	};

	const StepComponentList = [
		<Step1 key={'stupka-1'} dispatch={dispatch} />,
		<SingleMultiSelect
			key={'stupka-2'}
			dispatch={dispatch}
			dispatchHandle={'step2Results'}
			isSingle={false}
			selectOptions={step2Results}
			title={constants.step2Title}
			link={step1Results === 'ТВ + Интернет' ? 'tv-internet' : 'internet'}
			currentStep={currentStep}
		/>,
		<SingleMultiSelect
			key={'stupka-3'}
			dispatch={dispatch}
			dispatchHandle={'step3Results'}
			isSingle={true}
			selectOptions={step3Results}
			title={constants.step3Title}
			link={step1Results === 'ТВ + Интернет' ? 'tv-internet' : 'internet'}
			currentStep={currentStep}
		/>,
		<SingleMultiSelect
			key={'stupka-4'}
			dispatch={dispatch}
			dispatchHandle={'step4Results'}
			isSingle={true}
			title={constants.step4Title}
			selectOptions={step4Results}
			hasOptionalInput={true}
			tvCount={tvCount}
			link={step1Results === 'ТВ + Интернет' ? 'tv-internet' : 'internet'}
			currentStep={currentStep}
		/>,
		<SingleMultiSelect
			key={'stupka-5'}
			dispatch={dispatch}
			dispatchHandle={'step5Results'}
			isSingle={true}
			title={constants.step5Title}
			selectOptions={step5Results}
			link={step1Results === 'ТВ + Интернет' ? 'tv-internet' : 'internet'}
			currentStep={currentStep}
		/>
	];
	return (
		<>
			{goToConfigPackages ? (
				<>
					<FinalStep
						configPackages={configPackages}
						link={
							step1Results === 'ТВ + Интернет' ? 'tv-internet' : 'internet'
						}
					/>
				</>
			) : (
				<div
					className="d-flex justify-content-center"
					style={{ paddingTop: '16rem' }}
				>
					<div className="col-12 col-sm-11 col-xl-10 p-0 m-0">
						<Wrapper
							mainBg={data.mainBg.childImageSharp.fluid.src}
							bubbleBg={data.bubbleBg.childImageSharp.fluid.src}
						>
							<div className="row p-0 m-0 d-flex justify-content-center">
								<div className="col-md-6 col-lg-6 d-flex justify-content-center py-4 configs">
									<LeftSide progressPercentage={progressPercentage} />
								</div>
								<div className="col-12 col-lg-6 p-0 mt-2 mt-lg-0 p-lg-4 d-flex justify-content-end config-right">
									<div
										className="col-12 col-lg-11 p-0 m-0 py-lg-5 text-center text-lg-left config-card w-100"
										style={{ minHeight: '740px' }}
									>
										<h3>
											Въпрос{' '}
											<span className="text-green">
												{currentStep}
											</span>{' '}
											от {numberOfSteps - 1}
										</h3>
										{/* < Container which renders different steps > */}
										{StepComponentList[currentStep - 1]}
										{/* </ Container which renders different steps >*/}

										<div className="text-center text-lg-left my-5">
											<button
												disabled={!enableNextStep}
												className={
													'btn btn-primary green mx-2 my-2'
												}
												onClick={onButtonNextClick}
											>
												Следваща стъпка
											</button>
											{currentStep !== 1 && (
												<button
													className="btn btn-secondary mx-2 my-2"
													onClick={() =>
														dispatch({ type: 'prev_step' })
													}
												>
													Назад
												</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</Wrapper>
					</div>
				</div>
			)}
		</>
	);
};

export const StepTitle = ({ title }) => {
	return (
		<>
			<h2 className="my-lg-2 text-lg-left text-center">{title}</h2>
			<hr className="mb-5 center d-block d-lg-none" />
			<hr className="mb-5 d-none d-lg-block" />
		</>
	);
};

export default Configurator;

const LeftSide = ({ progressPercentage }) => {
	return (
		<div className="text-center col-12 col-sm-11 col-lg-7 py-md-4">
			<div className="">
				<img src={logoImg} className="img-fluid config-logo" alt="NetSurf лого" />
				<p className="text-white mt-4 px-2">
					Моля, отговори на въпросите, за да създадеш най-добрия пакет за теб
				</p>
			</div>
			<div className="col-12 text-center d-flex justify-content-center flex-column px-4">
				<img src={moneyGraphics} className="img-fluid" alt="Графика" />
				<div className="d-flex justify-content-between">
					<h3 className="text-white">Прогрес</h3>
					<h3 className="text-white">
						<span className="text-green">{progressPercentage}%</span>
					</h3>
				</div>
				<Line
					percent={progressPercentage}
					strokeColor="#60feb2"
					trailColor="#FFFFFF"
					strokeWidth={2}
					trailWidth={2}
				/>
			</div>
		</div>
	);
};

const Wrapper = styled.div`
	background: transparent;
	background-image: url(${(props) => props.mainBg}), url(${(props) => props.bubbleBg});
	background-position: top right, top left;
	background-repeat: no-repeat, no-repeat;
	background-size: cover, 52% 100%;
	border-radius: 16px;
	margin-bottom: 5rem;
	padding: 4rem 2rem 4rem 2rem;
	box-shadow: 0px 0px 38px rgba(17, 6, 29, 0.2);

	@media screen and (max-width: 991px) {
		background-size: cover, 120% 50%;
	}
`;

const query = graphql`
	query FinalConfiguratorQuery {
		bubbleBg: file(relativePath: { eq: "configurator/config-buble-bg.png" }) {
			childImageSharp {
				fluid(maxHeight: 2300) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		mainBg: file(relativePath: { eq: "configurator/config-main-bg.png" }) {
			childImageSharp {
				fluid(maxHeight: 2300) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		allPackages: allGoogleDevonlySheet {
			edges {
				node {
					id
					packageid: packageId
					speed
					package
					tvcount: tvCount
					price
					internalname: internalName
					informationButton
					timeshift: timeShift
					archive
					record
				}
			}
		}
	}
`;
